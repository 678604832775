.container {
  margin: 5% 0 0 5%;
  padding: 5% 5% 5% 5%;
  text-align: center;
  align-content: center;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

/* EmployeeList.css */

.employee-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.employee-table th, .employee-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.employee-table th {
  background-color: #f2f2f2;
}

.employee-table tr:hover {
  background-color: #f5f5f5;
}

/* EmployeeForm.css */

.employee-form {
  width: 300px;
  margin: 20px 0;
}

.employee-form label {
  display: block;
  margin-bottom: 8px;
}

.employee-form input {
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
}

.employee-form button {
  background-color: rgb(54, 151, 211);
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  width: 60%;
  border-radius: 5%;
}

.employee-form button:hover {
  background-color: #fff;
  border-style:initial;
  border-color: rgb(22, 69, 99);
  color: #000;
}


.edit-mode td input {
  width: 100%;
  padding: 6px;
}

.edit-mode button {
  background-color: #4caf50;
  color: white;
}

.edit-mode button:hover {
  background-color: #066ADB;
}
