.navbar {
  background-color: #000;
  padding: 2%;
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.navbar-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.navbar-item {
  margin: 2.5%;
}


.navbar-item a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 16px;
}

.navbar-item a:hover {
  color: #9EEB47;
}
